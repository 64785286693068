.myCard {
  width: 340px;
  height: 81px;

  background: rgba(255, 255, 255, 0.6);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ellipse {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  border-radius: 50%;
  
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  cursor: pointer;
}
.select-ellipse {
  background: #e47c30;
}

.image-card {
  width: 40px;
  height: 40px;
}

.letter-min {
  font-size: 13px;
  color: 000;
}

.aling-edit {
  text-align: right;
  cursor: pointer;
}

.btnSubmit {
	position: relative;
	right: 1rem;
	margin-left: 2rem !important;
	background-color: #e47c30 !important;
}

.shadow-1 {
	margin-top: 2rem;
	border-radius: 0px 110px 110px 0px;
	-webkit-box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.67);
	box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.67);
	overflow: visible !important;
}
@media (min-width: 768px) {
	.btnSubmit {
		right: 3rem;
	}
}

@media (min-width: 1024px) {
	.btnSubmit {
		left: 2rem;
	}
}

@media (min-width: 1366px) {
	.btnSubmit {
		left: 1.4rem;
	}
}

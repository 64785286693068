
.switch input {
    position: absolute;
    opacity: 0;
  }
  
  /**
   * 1. Adjust this to size
   */
  
  .switch {
    display: inline-block;
    font-size: 1.3em; /* 1 */
    height: 0.7em;
    width: 2em;
    
    background: #dfc4ae;
    border-radius: 1em;
    position: relative;
    right:1em;
   
  }
  
  .switch div {
      position: relative;
    height: 1em;
    width: 1em;
    margin-bottom: 1em;
    border-radius: 1em;
    background: #e47c30;
    bottom:0.1em;
    box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
    -webkit-transition: all 300ms;
       -moz-transition: all 300ms;
            transition: all 300ms;
  }
  
  .switch input:checked + div {
    -webkit-transform: translate3d(100%, 0, 0);
       -moz-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  
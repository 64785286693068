.w-80{
width: 50vh;
}

@media (min-width:  1024px) {
   

        .containerRegister{
            margin-top: 20vh;
            z-index: 2222;
            width: 100%;
            position: absolute;
        }
  }
@media (max-width:  1024px) {
    .w-80{
        width: 75%;
        }
  }
.opcionMenu {
	/* width: 100%; */
	border-radius: 10%;
	border: 3px solid #e87a3f;
	background-position: center;
	background-size: cover;
	position: relative;

	filter: grayscale(0.6);
}
.opMenu2 {
	height: 9em;
}

.opMenu3 {
	height: 7.5em;
	-webkit-border-radius: 18px 18px 18px 18px;
	-moz-border-radius: 18px 18px 18px 18px;
	border-radius: 18px 18px 18px 18px;
}

.bg-gray{
	background-color: #e9e9e9;
}

@media (min-width: 1366px) {
	.opcionMenu {
		height: 12em !important;
	}
}

.w-90 {
  width: 90vw;
}
.w-40 {
  width: 40vw;
}


.mt-7 {
  margin-top: 3rem;
}
.height-100 {
  height: 100vh !important;
}

.zIndex-5{
  z-index: 5000;
}

@media (max-width: 320px) {
  .shadow-1 {
    width: 100vw !important;
  }
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .play_video_modal_video{
        width:80vw !important;
        height:35vh !important;
    }
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .play_video_modal_video{
        width:80vw !important;
        height:35vh !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .play_video_modal_video{
        width:80vw !important;
        height:35vh !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .play_video_modal_video{
        width:90vw !important;
        height:50vh !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .play_video_modal_video{
        width:50vw !important;
        height:50vh !important;
    }
    
}
.btnRound {
  border-radius: 0px 98px 98px 0px;
  -moz-border-radius: 0px 98px 98px 0px;
  -webkit-border-radius: 0px 98px 98px 0px;
  border: 0px solid #aa0000;
  
}

.colorNaranja {
  background-color: #ed8f0c !important;
}
.colorAzul {
  background-color: #3b5998 !important;
}
.colorRojo {
  background-color: #ed340c !important;
}

.btnTitulo{
 font-size: 1rem;
 font-weight: 500;
}


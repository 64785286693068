.modalContainer {
	background-color: rgba(0, 0, 0, 0.603);
}
.modalBody {
	background-color: #fdc68a;
}
.modalRounded {
	border-radius: 29px 29px 29px 29px !important;
	-moz-border-radius: 29px 29px 29px 29px !important;
	-webkit-border-radius: 29px 29px 29px 29px !important;
}

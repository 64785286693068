.footer {
  position: relative;
  bottom: 0;
  left: 0;
}
.titulo {
  font-size: 10vw;
  font-weight: bolder;
}
.mt-6 {
  margin-top: -4rem;
}

@media (min-width: 1024px) {
  .left-x {
    left: 0 !important;
    top: 0 !important;
  }
  .left-y {
    right: 0 !important;
    top: 0 !important;
  }

  .w-40 {
    width: 20vw !important;
  }
  .titulo {
    font-size: 4vw;
    font-weight: bolder;
  }
}


@media (max-width: 400px) {

.waveContainer{
  margin-top: 5rem;
 
}
}
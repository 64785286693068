/* Rectangle 48 */

.rectangle-48{
    width: 296px;
    height: 48px;
    background: #FFFFFF;
    box-shadow: inset 1px 3px 3px 1px rgba(0, 0, 0, 0.25);
    border-radius: 89px;
    
    display: flex;
}

/* New Card */
.new-card{
    width: 110px;
    height: 22px;
    
    font-family: Spartan;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    
}

.rectangle-20{
    width: 148px;
    height: 48px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 89px;
    box-shadow:none;
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.bg-no-select{
    width: 148px;
    height: 48px;
    
    border-radius: 89px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    cursor: pointer;
}

.bg-select{
    transition: .2s;
    background: #E47C30;
}
.color-white{
    color: #FFFFFF;
}


/* My Cards */

.my-card{
    width: 103px;
    height: 22px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    
    font-family: Spartan;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    
}

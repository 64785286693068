.title-primary{
  font-family: Spartan;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 22px;

color: #2B2B2B;
}

.title-second {
  font-family: Spartan;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  /* identical to box height */

  color: #2b2b2b;
}

.options-card {
  padding-left: 20px;
}

.select-card-s {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding-left: 20px;
  /* identical to box height */
  width: 90%;
  color: #2b2b2b;
  height: 40px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(43, 43, 43, 0.6);
  box-sizing: border-box;
  border-radius: 10px;
}
.select-card-s:focus {
  outline: none;
}
.select-card-s:focus::-webkit-input-placeholder {
  color: #2b2b2b;
}
/* //el ancho de la caja de texto del select al dar clic    */
.input-card-credit{
    width: 90%;
    height: 40px;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid rgba(43, 43, 43, 0.6);
    box-sizing: border-box;
    border-radius: 10px;
}
.input-card-credit:focus {
  outline: none;
}
.input-card-credit:focus::-webkit-input-placeholder {
    color: #a19f9f;
}
.input-card-credit::-webkit-input-placeholder {
  color: #a19f9f;
}
.select-expire-date{
    width: 43%;
    height: 40px;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid rgba(43, 43, 43, 0.6);
    box-sizing: border-box;
    border-radius: 10px;
    margin-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}
.select-expire-date:focus {
    
  outline: none;
}
.select-expire-date:focus::-webkit-input-placeholder {
  color: #a19f9f;
}
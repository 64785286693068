.search{
    border-radius: 50px;
    border: 1px solid #ccc;
    margin-top: 15px;
    padding: 0;
    width: 100%;
    outline: none;
    font-size: 14px;
    color: rgb(39, 30, 30);
    height: 30px;
    padding: 10px;
}


.row{
    margin:0;
}
.col-5{
    padding: 0;
}

.opcionSubMenu {
	border-radius: 22px 22px 22px 22px;
	-moz-border-radius: 22px 22px 22px 22px;
	-webkit-border-radius: 22px 22px 22px 22px;
	border: 0px solid #000000;
	border: 0px solid #000000;
	border: 0px solid #000000;
	background-color: #f4cbac;
	overflow: hidden
}
.opcionSubMenuHeight {
	height: 10em !important;
	/* max-height: 10em!important; */
	
	
}
.opcionSubMenuHeight > img {
	height: 100% !important;
	
}
@media (max-width: 350px) {
	.col-xss-12 {
		width: 90vw !important;
		margin: 2em;
	}
}

@media (min-width: 1200px) {
	.opcionSubMenuHeight {
		height: 12em !important;
	
		
		
	}
}
